html, 
body {
  height: 115%;
  margin-bottom: 5%;
  padding-bottom: 5%;
  background:rgb(240, 235, 235);
  scroll-behavior: smooth;
}
#root {
  padding-bottom: 50px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 1.5vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.book,
.stackGrid {
  height: 500px;
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*************************************************************************/
h1 svg {
  max-width: 250px;
  cursor: pointer;
}
h2.nav {
  font-weight: 100;
  cursor: pointer;
  
  --s: 0.1em;   /* the thickness of the line */
  --c: #2c4bff; /* the color */
  
  color: #0000;
  padding-bottom: var(--s);
  background: 
    linear-gradient(90deg,var(--c) 50%,#000 0) calc(100% - var(--_p,0%))/200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) var(--s) no-repeat;
  -webkit-background-clip: text,padding-box;
          background-clip: text,padding-box;
  transition: 0.5s;
}

h2:hover {--_p: 100%}

nav,
section {
  margin: 0 auto;
  max-width: 1120px;
  padding: 25px;
  text-align: center;
}
@media (max-width: 500px) {
  nav {
    padding: 0;
  }
}

nav {
  animation: fadeInAnimation ease .5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
/*
video,
img {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
*/
main {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 1.5vmin);
  color: #000;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

main video {
  max-width: 200px;
}

.board-link {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: center center;
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.board-link::after {
  //box-shadow: 0 9px 30px rgb(245 144 66 / 12%), 0 1px 32px rgb(245 144 66 / 24%);
  //opacity: 0;
  //transition: opacity 0.3s ease-in-out;
}

.board-link:hover {
  //box-shadow: 5px 5px 5px rgb(245 144 66 / 12%), 0 5px 5px rgb(245 144 66 / 24%);
  //-webkit-transform: scale(1.25, 1.25);
  //transform: scale(1.25, 1.25);
}

.board-link:hover::after {
  opacity: 1;
}

.book-title {
  background-color: rgba(255,255,255,.7);
  position: absolute;
  font-size: 10px;
  font-family: sans-serif;
  padding: 2px;
  bottom: 0;
  right: 0;
  display: none;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

figure {
  //background-color: #fff;
  z-index: 5;
  position: relative;
}

.text,
figure .text {
  padding: 10px;
  border-radius: 8px;
  background-color: #00acee;
  color: #fff;
  font-size: .40em;
  text-align: center;
  font-weight: 100;
}

.text hr {
  color: #fff;
  border: 1px solid white;
  width: 75%;
}
figure img {
  background-color: #fff;
}

.text,
figure img,
figure video {
  transition: all .35s cubic-bezier(.25,.8,.25,1); /* Animation */
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

figure.svg img {
  background: none;
  box-shadow: none;
}

.text:hover ,
figure:hover img,
figure:hover video{
  box-shadow: 5px 12px 50px rgba(0,0,0,0.05), 0 8px 8px rgba(0,0,0,0.05);
  cursor: pointer;
  transform: scale(1.05);
}
figure:hover .svg img {
  background: none;
  box-shadow: none;
}
.video-preloader {
  width: 1px;
  height: 1px;
}
.video-loaded {
  animation: fadeInAnimation ease 2.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  cursor:crosshair;
}
.video-loaded::-webkit-media-controls { /* Works only on Chrome-based browsers */
  display: none;
}

.play-btn {
  position: absolute;
  bottom: 4%;
  left: 4%;
  z-index: 100;
  width: 12%;
  height: auto;
  margin: 0 auto;
  margin-top: 10%;
}

.play-btn__svg{
  transition: 1s; 
  opacity: .7;
  fill:#ffea00; 
}

.play-btn:hover .play-btn__svg {
  opacity: 1;
  fill: #1561a3;
}
.home {
  max-width: 1120px;
  //width: 100%;
  margin: 0 auto;
  text-align: left;
  text-indent: 20px;
}
@media (max-width: 500px) {
  .home {
    max-width: 350px;
    text-align: center;
    text-indent: 0px;
  }
}

.home img {
  max-width: 200px;
  transition: opacity .10s linear;
  cursor: pointer;
}
.home img:hover {
  opacity: 55%;
}

footer {
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  padding: 5px;
  text-transform: lowercase;
  position: fixed;
  bottom: 0;
  right: 0;
  background: rgb(240, 235, 235);
  z-index: 0;
}

footer span,
footer a {
  display: inline-block;
}
footer a img {
  width: 20px;
  margin-left: 5px;
}
@media (max-width: 400px) {
  footer {
    right: auto;
  }
}

#modal {
  color: #fff;
  top: 0;
  left: 250px;
  padding: 25px;

  background-color: #fff;
  width: 100%;
  max-width: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 10px 60px rgba(0,0,0,0.12), 0 10px 30px rgba(0,0,0,0.24);
  border-radius: 5px;
  position: fixed;
  justify-content: center;
}
@media (max-width: 900px) {
  #modal {
    max-width: 400px;
  }
  #modal img {
    max-width: 65%;
  }
}
@media (max-width: 600px) {
  #modal {
    max-width: 300px;
  }
  #modal img {
    max-width: 65%;
  }
}


#modal-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  backdrop-filter: blur(10px);
  //background: rgba(24, 26, 24, 0.731);
  top: 0;
  z-index: 90001;
  display: flex;
  justify-content: center;
}
#modal a {
  display: inherit;
}

#modal video {
  width: 100%;
}

.preload {
  visibility: hidden;
}
aside {
  /*EJS root work around*/
  display: none;
  /* <aside><%- OG %></aside> */
}
/*
* Album CSS form old Pinbook
*
*
*


.nsfw img,
.nsfw video {
	transition: 1s filter linear;
	-webkit-transition: 1s -webkit-filter linear;
	-moz-transition: 1s -moz-filter linear;
	-ms-transition: 1s -ms-filter linear;
	-o-transition: 1s -o-filter linear;
}

img.nsfw {
	 filter: grayscale(100%);
	filter: blur(15px);
  	-webkit-filter: blur(15px);
  	opacity: .35 !important;
  	transition: 2s linear all;
}

video.nsfw {
	 filter: grayscale(100%);
	filter: blur(15px);
  	-webkit-filter: blur(15px);
  	opacity: .35 !important;
}

*/
.album-wrapper {
  cursor: grab;
}

.album-wrapper .album-item {
  
  //margin-bottom: 5px;
  /* /argin-top: 5px; */
  
}
.album-wrapper .album-item.top {
  position: relative;
  z-index: 1500;
}
.album-wrapper:hover .album-item.top {
  z-index: 1705;
}
.album-wrapper .album-item:not(:first-of-type) {
  position: relative;
  //z-index: 100;
}
.album-wrapper .album-item:not(:first-of-type) {
  //border: 10px solid #000;
  position: absolute;
  top: 0;
}

.album-img {
  background-color: #fff;
}
.album-img,
.album-video {
  //position: absolute;
  display: block !important;
  top: 0;
  left: 0;
  transition: all 0.3s;
  box-shadow: 0 1px 3px rgba(0,0,0,0.52), 0 1px 2px rgba(0,0,0,0.54);
  border: 3px solid #fff;
  border-radius: 5px;
}
.album-ghost{
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
}

/*
.album-wrapper .album-item:nth-of-type(1) .album-img {
  transform: rotate(1deg);
  z-index: 2010;
  //border: 5px solid #fff;
}
.album-wrapper:hover .album-item:nth-of-type(1) .album-img {
  //transform: rotate(-1deg);
}
*/
.album-wrapper:hover {
}

.album-wrapper .album-item:nth-of-type(2) .album-img {
  transform: rotate(-5deg);
  //opacity: 80%;
}
.album-wrapper .album-item:nth-of-type(3) .album-img {
  //opacity: 85%;
  transform: rotate(5deg);
}

.album-wrapper .album-item:not(:first-of-type) {
  transition: all 0.4s;
  /*
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,.25)));
      mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,.25));
  */
}
.album-wrapper:hover .album-item:not(:first-of-type) .album-img,
.album-wrapper:hover .album-item:not(:first-of-type) .album-video {
  opacity: 100%;
  //mask: none;
}


.album-wrapper:hover .album-item:nth-of-type(2) .album-img {
  transform:  translate(-60%, -20px) rotate(-7deg);
}
.album-wrapper:hover .album-item:nth-of-type(3) .album-img {
  transform:  translate(60%, -20px) rotate(7deg);
}
.album-wrapper:hover .album-item:not(:first-of-type) {
  z-index: 1702;
}
.gradient {
  background: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73));
  //min-height: 200px;
  width: 100%;
  position: absolute;
  top: 0;
}